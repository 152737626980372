<script lang="ts" setup>
  const props = defineProps<{
    blok: any
  }>()

  const blokData = ref(props.blok)

  watch(
    () => props.blok,
    () => {
      blokData.value = props.blok
    }
  )
</script>

<template>
  <div class="static-page px-4 lg:!px-0">
    <template
      v-for="block in blokData.body || []"
      :key="block._uid"
    >
      <StoryblokComponent :blok="block" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  @import '@/assets/styles/common.scss';

  .static-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
  }

  .default .static-page {
    @include tablet-up {
      width: 70%;
    }

    @include desktop-up {
      width: 100%;
      max-width: 740px;
    }
  }
</style>
